export const Paths = {
  HOME: "/",
  ABOUT: "/about",
  PORTFOLIO: "/portfolio",
  PROJECT1: "/project1",
  PROJECT3: "/project3",
  PROJECT4: "/project4",
  PROJECT6: "/project6",
  RESUME: "/resume",
};
